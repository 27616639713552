import React, { useEffect } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import { Layout, Title, SectionCenter, PrimaryButton, SEO } from "../components"
import { getCurrentPath } from "../redux/actions"
import notFound from "../../images/page-not-found.svg"
import { mq } from "../utils/media-query"

const NotFound = ({ getCurrentPath }) => {
  const intl = useIntl()
  useEffect(() => {
    getCurrentPath("/not-found")
  }, [])

  const browser = typeof window !== "undefined" && window

  return (
    browser && (
      <Layout>
        <SEO title="Not Found" />
        <section
          css={css`
            padding: 5rem 0;
          `}
        >
          <Title
            title={intl.formatMessage({ id: "404.text" })}
            fontColor="var(--clr-primary-300)"
          />
          <SectionCenter>
            <div
              css={css`
                width: 90%;
                margin: 0 auto;
                text-align: center;
              `}
            >
              <img
                src={notFound}
                alt="page-not-found"
                css={css`
                  width: 40%;

                  ${mq[0]} {
                    width: 30%;
                  }
                `}
              />
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin: 4rem auto 0 auto;
              `}
            >
              <PrimaryButton
                textSize="0.875rem"
                textTransform="capitalize"
                maxWidth="none"
                margin="0"
                onClick={() => navigate("/")}
              >
                {intl.formatMessage({ id: "btn.return" })}
              </PrimaryButton>
            </div>
          </SectionCenter>
        </section>
      </Layout>
    )
  )
}

export default connect(null, { getCurrentPath })(NotFound)
